import { BaseButton } from 'components';
import { API_URL } from 'configs';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './ContactForm.module.css';

export const ContactForm = (props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const onClose = (e) => {
    props?.onClose(e);
  };

  const onSubmit = (data) => {
    const requestOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };

    Promise.all([
      fetch(`${API_URL}/feedback/messages/`, requestOptions).then((response) =>
        response.json(),
      ),
    ])
      .catch((error) => console.error(error))
      .finally(() => {
        setIsSubmitted(true);
        reset({ email: '', message: '' });
        setTimeout(() => onClose(), 1300);
      });
  };

  const showSuccessResponce = () => {
    return (
      <div className={styles['success-checkmark']}>
        <div className={styles['check-icon']}>
          <span
            className={`${styles['icon-line']} ${styles['line-tip']}`}
          ></span>
          <span
            className={`${styles['icon-line']} ${styles['line-long']}`}
          ></span>
          <div className={styles['icon-circle']}></div>
          <div className={styles['icon-fix']}></div>
        </div>
        <span className={styles['success-message']}>
          Дякуємо! Ваше повідомлення відправленно.
        </span>
      </div>
    );
  };

  const showForm = () => {
    return (
      <div>
        <div className={styles['contact-form-header']}>
          <img
            src="/images/contact-form-icon.svg"
            alt="Іконка зворотнього зв'язку"
          />
          <span>Анонімна форма зворотнього зв'язку</span>
          <div className={styles['form-close']} onClick={() => onClose()}>
            <div className={styles['form-line1']}></div>
            <div className={styles['form-line2']}></div>
            <div className={styles['form-line3']}></div>
          </div>
        </div>
        <span className={styles['contact-form-header-subtitle']}>
          Запитай про те, що тебе цікавить, і ми тобі відповімо ...
        </span>
        <form
          id="contactform"
          name="contactForm"
          className={styles['contact-form-body']}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.loginForm}>
            <div
              className={styles['wrap-login']}
              data-validate="Enter username"
            >
              <input
                type="email"
                id="feedback-email"
                name="feedback-email"
                {...register('email', { required: true })}
                onBlur={(e) =>
                  e.target.value !== ''
                    ? e.target.classList.add('has-val')
                    : e.target.classList.remove('has-val')
                }
                className="input100"
              />
              <span
                className="focus-input100"
                data-placeholder="Електронна пошта"
              ></span>
            </div>
            <div className={styles['textarea-section']}>
              <textarea
                name="feedback-message"
                id="feedback-message"
                placeholder="Напишіть ваше повідомлення тут..."
                {...register('message', { required: true })}
              />
            </div>
          </div>

          <BaseButton
            type="submit"
            className="submit-form-btn"
            onClick={handleSubmit}
          >
            Відправити
          </BaseButton>
        </form>
      </div>
    );
  };

  return (
    <div className={styles['contact-form-block']}>
      <div className={styles['contact-form-container']}>
        {isSubmitted ? showSuccessResponce() : showForm()}
      </div>
    </div>
  );
};

export default ContactForm;
