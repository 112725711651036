import React from 'react';

import styles from './BaseIcon.module.css';

export const BaseIcon = ({ targetUrl, backgroundImage }) => {
  return (
    <span className={styles['icon']}>
      <a
        href={targetUrl ?? null}
        className={styles.icon}
        target="_blank"
        rel="noopener noreferrer"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></a>
    </span>
  );
};

export default BaseIcon;
