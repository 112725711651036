import React, { createContext, useContext, useEffect, useState } from 'react';

import { API } from 'configs';
import { TokenService } from 'services';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [errors, setErrors] = useState();

  const isAuthenticated = !!user;

  useEffect(() => {
    TokenService.getAccessToken() && getCurrentApiUser();
  }, []);

  const getCurrentApiUser = async () => {
    const currentUser = await API.get('auth/users/me/').then((responce) => {
      if (!responce.data) {
        return null;
      }

      setUser(responce.data);

      return responce.data;
    });

    return currentUser;
  };

  const login = async (userLoginData) => {
    const userTokens = await API.post('auth/jwt/create', userLoginData).then(
      async (responce) => {
        if (!responce.data) {
          return null;
        }

        TokenService.setAccessToken(responce.data.access);
        TokenService.setRefreshToken(responce.data.refresh);

        await getCurrentApiUser();

        return responce.data;
      },
      (error) => {
        setErrors(error.response.data.detail);
      },
    );

    return userTokens;
  };

  const logout = () => {
    setUser(null);

    TokenService.removeAccessToken();
    TokenService.removeRefreshToken();
  };

  const contextValue = {
    authErrors: errors,
    isAuthenticated,
    user,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default useAuth;
