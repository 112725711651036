import React from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';

import customMapStyle from './mapStyles';

export const MapContainer = () => {
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const options = {
    styles: customMapStyle,
    disableDefaultUI: true,
    zoomControl: true,
  };

  const center = {
    lat: 46.646445,
    lng: 32.629634,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCf9xVQpaYxvzLN-6URK-z0UGzEbCCP-Qs',
  });

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={17}
      center={center}
      options={options}
    ></GoogleMap>
  );
};

export default React.memo(MapContainer);
