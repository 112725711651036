import axios from 'axios';
import { TokenService } from 'services';
import { API_URL } from './env';

export const API = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getAccessToken();
    if (accessToken) {
      config.headers['Auth'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const axiosConfig = error.config;
    const resfreshToken = TokenService.getRefreshToken();
    if (
      error.response?.status === 401 &&
      !axiosConfig._retry &&
      resfreshToken
    ) {
      axiosConfig._retry = true;
      const accessTokenResponse = await API.post('/auth/jwt/refresh', {
        refresh: resfreshToken,
      });

      if (accessTokenResponse.status !== 200) {
        TokenService.removeRefreshToken();
        return Promise.reject(error);
      }

      const { access } = accessTokenResponse.data;
      TokenService.setAccessToken(access);

      return API(axiosConfig);
    }

    return Promise.reject(error);
  },
);

export default API;
