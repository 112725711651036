import React, { useEffect, useState, lazy } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faLock,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';

import './Header.css';
import { useAuth } from 'contexts';
import MailIcon from 'assets/images/social-media-icons/mail.svg';
import FacebookIcon from 'assets/images/social-media-icons/facebook.svg';
import InstagramIcon from 'assets/images/social-media-icons/instagram.svg';
import TelegramIcon from 'assets/images/social-media-icons/telegram.svg';

const BaseIcon = lazy(() => import('components/BaseIcon/BaseIcon'));

export const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const { isAuthenticated } = useAuth();

  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 300) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={scrolled ? 'fixed-header' : null}>
      <nav>
        <div className="header-logo">
          <Link to="/">
            <span>
              Студ
              <img
                src="/images/logos/logo_x144.png"
                alt="Логотип студентського містечка Херсонського державного університету (ХДУ)"
              />
              Містечко
            </span>
          </Link>
        </div>

        <ul className={`nav-links ${openMenu ? `nav-active` : null}`}>
          <li>
            <Link to="/services/">Поселення</Link>
          </li>
          <li>
            <Link to="/hotel/">Готель</Link>
          </li>
          <li>
            <Link to="/about-us/">Про Нас</Link>
          </li>
          <li
            className={`dropdown-click dropdown-hover ${
              openSubMenu ? `mobile-dropdown` : null
            }`}
          >
            <button onClick={() => setOpenSubMenu(!openSubMenu)}>
              Корисна Інформація
              <FontAwesomeIcon icon={faAngleDown} />
            </button>
            <ul className="dropdown">
              <li>
                <Link to="/news/">Новини</Link>
              </li>
              <li>
                <Link to="/gallery/">Галерея</Link>
              </li>
              <li>
                <Link to="/statistics/">Статистика</Link>
              </li>
              <li>
                <Link to="/directorate/">Адміністрація</Link>
              </li>
              <li>
                <Link to="/students/">Студентська рада</Link>
              </li>
              <li>
                <Link to="/documents/">Документація</Link>
              </li>
              <li>
                <Link to="/contacts/">Контакти</Link>
              </li>
            </ul>
          </li>

          {isAuthenticated && (
            <li className="responsive-login-button">
              <a href="/admin/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLock} size="2x" />
              </a>
            </li>
          )}

          <li className="responsive-login-button">
            <Link to="/profile">
              <FontAwesomeIcon icon={faSignInAlt} size="2x" />
            </Link>
          </li>

          <ul className="article-share mob">
            <li className="article-share-mob-icon">
              <BaseIcon backgroundImage={MailIcon} targetUrl={``} />
              <BaseIcon backgroundImage={FacebookIcon} targetUrl={``} />
              <BaseIcon backgroundImage={InstagramIcon} targetUrl={``} />
              <BaseIcon backgroundImage={TelegramIcon} targetUrl={``} />
            </li>
          </ul>
        </ul>

        <div className="burger" onClick={() => setOpenMenu(!openMenu)}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>

      <div
        className={`body-shadow ${openMenu ? `nav-active` : null}`}
        onClick={() => setOpenMenu(!openMenu)}
      ></div>
    </header>
  );
};

export default Header;
