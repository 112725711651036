import React, { useState, useEffect } from 'react';

import { API } from 'configs';
import LazyLoad from 'react-lazyload';
import Map from '../Map/Map';
import styles from './Footer.module.css';

export const Footer = () => {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState(null);

  useEffect(() => {
    const fetchContacts = () =>
      API.get(`about-us/contacts/`).catch((error) => console.log(error));

    const fetchData = async () => {
      const [retrievedContactsResponce] = await Promise.all([fetchContacts()]);

      setContacts(retrievedContactsResponce?.data[0]);
    };

    fetchData();
    setLoading(false);
  }, []);

  return (
    <footer>
      {loading ? null : (
        <div className={styles['container-block']}>
          <div className={styles['contact-info']}>
            <span>Контактна інформація</span>
            <span>
              <p>
                Дирекція студентського містечка ХДУ:
                <b> {contacts?.address}</b>
              </p>
              {contacts?.phone_number && (
                <p>
                  Телефон:
                  <a href={`tel:+${contacts?.phone_number}`}>
                    {contacts?.phone_number}
                  </a>
                </p>
              )}

              {contacts?.hostels?.map(
                ({ id, number, address, phone_number }) => (
                  <div key={id}>
                    <p>
                      Гуртожиток №{number}: <b> {address}</b>
                    </p>
                    <p>
                      Телефон:
                      <a href={`tel:+${phone_number}`}> {phone_number}</a>
                    </p>
                  </div>
                ),
              )}
            </span>
          </div>

          <div className={styles['contact-info']}>
            <span>Графік роботи</span>
            <span>
              <p>
                Пн-Чт: <b>8.30 - 17.15</b>
              </p>
              <p>
                Пт: <b>8.30 - 16.00</b>
              </p>
              <p>
                Обідня перерва: <b>12.00 - 12.30</b>
              </p>
              <p>
                Сб-Нд: <b>вихідні</b>
              </p>
              {contacts?.email && (
                <p>
                  Email:
                  <a href={`mailto:${contacts?.email}`}> {contacts?.email}</a>
                </p>
              )}
            </span>
          </div>
          <div className={styles['footer-map']}>
            <LazyLoad height={500} once offset={100}>
              <Map />
            </LazyLoad>
          </div>
        </div>
      )}
      <div className={styles['copyright-part']}>
        <span>
          Copyright &copy;{`2019 - ${new Date().getFullYear()} `}
          Maxim Vinnik, Maxim Poltorackii, Oleksandr Lemeshchuk, Vladislav
          Romanenko, Kyrylo Valiaiev © Kherson State University. Kherson,
          Ukraine 73000.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
