import React from 'react';

import './BaseButton.css';

export const BaseButton = (props) => {
  return (
    <button
      type={props?.type}
      className={props?.className}
      onClick={props?.onClick}
    >
      {props?.children}
    </button>
  );
};

export default BaseButton;
