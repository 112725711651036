const accessTokenKey = 'accessToken';
const refreshTokenKey = 'refreshToken';

export const TokenService = {
  getAccessToken: () => localStorage.getItem(accessTokenKey) ?? '',
  getRefreshToken: () => localStorage.getItem(refreshTokenKey) ?? '',
  removeAccessToken: () => localStorage.removeItem(accessTokenKey),
  removeRefreshToken: () => localStorage.removeItem(refreshTokenKey),
  setAccessToken: (value) => localStorage.setItem(accessTokenKey, value),
  setRefreshToken: (value) => localStorage.setItem(refreshTokenKey, value),
};

export default TokenService;
