import React from 'react';
import { LightgalleryItem } from 'react-lightgallery';

export const ImageItem = ({ groupName, imageUrl }) => (
  <div>
    <LightgalleryItem group={groupName} src={imageUrl}>
      <img src={imageUrl} />
    </LightgalleryItem>
  </div>
);

export default ImageItem;
