import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import { LightgalleryProvider } from 'react-lightgallery';

import { Loader, PageLoader, ProtectedRoute, ScrollToTop } from './components';
import { AuthProvider } from './contexts';
import './App.css';

const Header = lazy(() => import('./components/Header/Header'));
const Footer = lazy(() => import('./components/Footer/Footer'));

const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
const Contacts = lazy(() => import('./pages/Contacts/Contacts'));
const Directorate = lazy(() => import('./pages/Directorate/Directorate'));
const Documents = lazy(() => import('./pages/Documents/Documents'));
const Gallery = lazy(() => import('./pages/Gallery/Gallery'));
const Home = lazy(() => import('./pages/Home/Home'));
const Hotel = lazy(() => import('./pages/Hotel'));
const Login = lazy(() => import('./pages/Login/Login'));
const News = lazy(() => import('./pages/News/News'));
const PageNotFound = lazy(() => import('./pages/PageNotFound/PageNotFound'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const Registration = lazy(() => import('./pages/Registration/Registration'));
const Services = lazy(() => import('./pages/Services/Services'));
const Statistics = lazy(() => import('./pages/Statistics/Statistics'));
const Students = lazy(() => import('./pages/Students/Students'));

const Main = withRouter(({ location }) => {
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Header />
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/documents/" component={Documents} />
            <Route path="/login" component={Login} />
            <Route path="/contacts/" component={Contacts} />
            <Route path="/documents/" component={Documents} />
            <Route path="/directorate/" component={Directorate} />
            <Route path="/hotel/" component={Hotel} />
            <Route path="/students/" component={Students} />
            <Route path="/gallery/" component={Gallery} />
            <Route path="/statistics/" component={Statistics} />
            <Route path="/about-us/" component={AboutUs} />
            <Route path="/services/" component={Services} />
            <Route path="/registration/" component={Registration} />
            <Route path="/news/" component={News} />
            <ProtectedRoute exact path="/profile/" component={Profile} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Suspense>
        {location.pathname !== '/contacts/' &&
          location.pathname !== '/login' &&
          location.pathname !== '/registration' && <Footer />}
      </Suspense>
    </div>
  );
});

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <LightgalleryProvider>
          <Main />
        </LightgalleryProvider>
      </Router>
    </AuthProvider>
  );
};

export default App;
