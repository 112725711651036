import FacebookIcon from 'assets/images/social-media-icons/facebook.svg';
import InstagramIcon from 'assets/images/social-media-icons/instagram.svg';
import MailIcon from 'assets/images/social-media-icons/mail.svg';
import TelegramIcon from 'assets/images/social-media-icons/telegram.svg';
import YoutubeIcon from 'assets/images/social-media-icons/youtube.svg';
import ContactForm from 'components/ContactForm/ContactForm';
import React, { lazy, useState } from 'react';
import styles from './SidebarPanel.module.css';

const BaseIcon = lazy(() => import('components/BaseIcon/BaseIcon'));

export const SidebarPanel = ({ contacts }) => {
  const [isContactFormShown, setIsContactFormShown] = useState(false);

  const toggleContactForm = () => setIsContactFormShown(!isContactFormShown);

  return (
    <>
      <div className={`${styles['article-share']} ${styles['large-screen']}`}>
        <BaseIcon
          backgroundImage={MailIcon}
          targetUrl={contacts?.email && `mailto:${contacts?.email}`}
        />
        <BaseIcon
          backgroundImage={FacebookIcon}
          targetUrl={contacts?.facebook_url}
        />
        <BaseIcon
          backgroundImage={InstagramIcon}
          targetUrl={contacts?.instagram_url}
        />
        <BaseIcon
          backgroundImage={TelegramIcon}
          targetUrl={contacts?.telegram_url}
        />
        <BaseIcon
          backgroundImage={YoutubeIcon}
          targetUrl={contacts?.youtube_url}
        />

        <span
          className={`${styles['contact-form-icon']} ${styles['form-icon']}`}
          onClick={() => toggleContactForm()}
        ></span>
        {isContactFormShown ? (
          <ContactForm onClose={() => toggleContactForm()} />
        ) : null}
      </div>
    </>
  );
};

export default SidebarPanel;
